import * as React from 'react'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const ServicesPage = () => {
  return (
    <Layout>
      <MustRead />
    </Layout>
  )
}

export default ServicesPage